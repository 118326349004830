import request from '@/utils/request'

const exhibition_commodityApi = {
  exhibition_commodity_list: '/exhibition_commodity/',
  exhibition_commodity_create: '/exhibition_commodity/',
  exhibition_commodity_record_list: '/exhibition_commodity/record/',
  exhibition_commodity_update: '/exhibition_commodity/',
  exhibition_commodity_delete: '/exhibition_commodity/'
}

/**
 * 列表
 */
export function exhibition_commodity_list (parameter) {
  return request({
    url: exhibition_commodityApi.exhibition_commodity_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function exhibition_commodity_record_list (parameter) {
  return request({
    url: exhibition_commodityApi.exhibition_commodity_record_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function exhibition_commodity_create (parameter) {
  return request({
    url: exhibition_commodityApi.exhibition_commodity_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function exhibition_commodity_update (parameter, exhibition_commodity_id) {
  return request({
    url: exhibition_commodityApi.exhibition_commodity_update + exhibition_commodity_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function exhibition_commodity_delete (exhibition_commodity_id) {
  return request({
    url: exhibition_commodityApi.exhibition_commodity_delete + exhibition_commodity_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
